import * as React  from 'react';
import { graphql } from 'gatsby'
import Layout from '../../components/layout/index';
import CardMetaData from '../../components/cardMetaData';
import DescriptionContainer from '../../components/cardDetailsDescription/descriptionContainer';
import BestForIcons from '../../components/bestForIcons';
import {cardDataDisplaySchema} from '../../utilities/schema';
import {getMetaTagContentForCardPage} from '../../utilities/utilfns';

import "./index.css";

function CardPage({data}){
    const cardData = data.mongodbMmcnewCarddatas;
    const contentFulData = data.contentfulCardDetails;
    return (
        <Layout> 
            <div  className='card-page-container'>
                <CardMetaData key={cardData.key} cardDetails={cardData} showBestFor={false}> 
                    { cardData.bestFor && cardData.bestFor.length > 0 && <div className='cards-meta-footer'> 
                        <div className='cards-meta-footer-sec'> 
                            <span> {"Best For : "} </span> 
                            <BestForIcons bestFor={cardData.bestFor}/>
                        </div>
                        {/* <div className='cards-meta-footer-sec'>
                            <span> {"Partner Brands : "} </span> 
                            <div className='card-icons-container'> <img className='card-data-icon' src="https://i.postimg.cc/7YdMjLph/swiggy.png" />  </div>
                        </div> */}
                    </div>}
                </CardMetaData>
                
                <div className='card-data-group-container'> 
                    {cardDataDisplaySchema.map( ele => ( Array.isArray(ele) ? (
                      <div className='card-data-group-elements'>
                        {ele.map( item => <DescriptionContainer key={item.key} groupMeta={item} cardData={cardData} customContainerStyle={{width:"30%"}}> </DescriptionContainer> )}
                      </div>) : <DescriptionContainer key={ele.key} groupMeta={ele} cardData={cardData} > </DescriptionContainer>) )}
                </div>

            </div>
             { contentFulData &&  contentFulData?.cardContent?.cardContent &&  <div className='card-page-container'>
                <div className='card-data-group-container'> 
                  <div dangerouslySetInnerHTML={{__html : `${contentFulData?.cardContent?.cardContent}`}} />
                </div>
            </div> }
        </Layout>
    )
}

export default CardPage;

export const Head = ({data}) => {
  const cardData = data.mongodbMmcnewCarddatas;
  const content  = getMetaTagContentForCardPage(cardData);

  // {Bank Name} {Card Name} Credit Card - Latest Benefits, Fees, Eligi
  return (<React.Fragment>
      <title>  {cardData.displayName + " - Latest Benefits, Fees, Eligibility" }  </title>
      <meta name="description"  content={content} />
  </React.Fragment>)
}


export const query = graphql`
query($key: String) {
    contentfulCardDetails(slug: {eq: $key}) {
      slug
      cardContent {
        cardContent
      }
    }
    mongodbMmcnewCarddatas(key: {eq: $key}) {
      id
      key
      displayName
      cardType
      cardCategory
      bankId
      applyLinkUrl
      imageUrl
      applyLinkUrl
      isActive
      bestFor
      joiningAnnualAndMilestoneBenefits {
        annualBeneift
        joiningBenefit {
          shortDescription
          longDescription
        }
        milestoneBenefit {
          annualMilestoneBenefit
          monthlyMilestoneBenefit
          quarterlyMilestoneBenefit
        }
      }
      rating 
      rewards {
        accerlatedRewards
        capping
        offline {
          estimatePercentageSavings
          rewardsOnSpends
        }
        online {
          estimatePercentageSavings
          rewardsOnSpends
        }
        spendCategoryThatWillNotEarnReward
        rewardsList{
          text
          percentage
        }
        milestoneBenefit {
          annualMilestoneBenefit
          monthlyMilestoneBenefit
          quarterlyMilestoneBenefit
        }
      }
      savings {
        details
        percentage
      }
      tipsByCredzy {
        idealMonthlySpends
        idealSpendsCategories
        idealYearlySpends
      }
      features {
        airportMeetGreetService
        airportTransferService
        golf {
          game
          lessons
        }
        insurance {
          airAccidentalInsurance
          lostCardLiabilitycover
          personalAccidentalInsurance
        }
        loungeAccess {
          forCardHolders {
            domestic
            international
            railway
          }
          forGuest {
            domestic
            international
            railway
          }
          loungeAccessMembership
        }
        membershipWithCard
        spa
      }
      rewardsRedemption {
        rewardRateOfEachOption
        rewardRedemptionFees
        rewardRedemptionOption
        rewardTransferPartner
        redemptionList
      }
      eligibility {
        maximumAge
        minimumAge
        minimumITR
        minimumSalary
        residentialStauts
      }
      fuelSpendBenefits {
        maxSurchargeWaiver
        maxTransactionAmount
        minTransactionAmount
        waiver
      }
      feesAndCharges {
        annualFees
        feeReversalCriterai
        forexMarkupCharges
        joiningFees
      }
    }
  }
`