import React, { Fragment } from "react";
import { getProperty } from 'dot-prop';

import "./styles/index.css";

function DefaultContainer(props){
    const {data = {}, bluePrint = {}} = props;
    function getData(key){
        let value  = "";
        if(Array.isArray(key)){
            let parsedData = key.reduce( (acc, ele) => {  
                const value = getProperty(data, ele ,"");
                if(value.length > 0 && !acc.includes(value)){
                    acc.push(value);
                }
                return acc; 
            }, [] )
            value =  parsedData.length > 1 ? parsedData : parsedData.toString();
        }else{
            value =  getProperty(data, key , "");
        }
        let tempValue = Array.isArray(value) && value.length >= 1 ? getArrayValue(value) : value.toString();
        return  tempValue == "Not Available" || tempValue == "" ? "N/A" : tempValue
    }

    function getArrayValue(value){
        const values =  value.map( ele => typeof(ele) === "string"  ? ele :  ( <Fragment><b> {ele.percentage} </b>  <span>{ele.text} </span> </Fragment>)  );
        return values;
    }

    function getElementData(details){
        const { key, type, displayName, displayKeys , isGroup, customStyle} = details;
        return (
            <div className="mini-container" style={{ ...customStyle.container }} key={key.toString()}> 
                { <b style={{fontWeight:"600", ...customStyle.title}}> {displayName + " : "}  </b> }
                {displayKeys ? (
                    <ul>
                        { displayKeys.map( ele =>  (<li> {getElements(ele)} </li>))} 
                    </ul>
                ) 
                :  Array.isArray(getData(key)) 
                    ? (<ul> {getData(key).map( ele => <li key={ele}> {ele} </li> )} </ul>)   
                    : <span> {getData(key)} </span>  }
            </div>
        )
    }

    function getElements(details){
        const {isGroup} = details;    
        const customStyle = { container : { margin : "1.5% 0%", flexBasis : "45%" } , title: {  color: "#3D8BFF" } };
        const customStyleSingle = { container : { margin : "0.5rem 0rem" } , title: {  color: "#3D8BFF" } };
            return (
                isGroup ?  <div className="card-data-group-container-default">  {details.items.map( ele =>  getElementData({...ele, customStyle: details.items.length > 1 ?  customStyle : customStyleSingle }) ) }  </div>
                : getElementData({...details, customStyle : {}})
            )
     
        
    }


    return (<div>  {bluePrint.displayKeys.map( ele => getElements(ele) ) } </div> )
}



export default DefaultContainer;
