import React, { Fragment } from "react";
import { groupDisplayBluePrint } from '../../utilities/schema';
import Fees from './fees';
import DefaultContainer from './defaultContainer';
import "./styles/index.css";


function DescriptionContainer(props){

    const { groupMeta , cardData = {} , customContainerStyle } = props;

    return (
        <div key={groupMeta.key} className="group-data-container" style={customContainerStyle}> 
            <div className="group-data-title">
                <div className="category-title"> {groupMeta.displayName} </div> 
            </div>
            <div>
                {groupMeta.key === "feesAndCharges" ?  ( <Fragment>
                    <DefaultContainer 
                        bluePrint={groupDisplayBluePrint["feesAndCharges"]} 
                        data={cardData["feesAndCharges"]}/> 
                    <DefaultContainer 
                        bluePrint={groupDisplayBluePrint["joiningAnnualAndMilestoneBenefits"]} 
                        data={ cardData["joiningAnnualAndMilestoneBenefits"]}/> 
                    <DefaultContainer                        
                        bluePrint={groupDisplayBluePrint["otherCharges"]} 
                        data={ cardData["feesAndCharges"]}/> 
                    </Fragment>
                )
                    : <DefaultContainer 
                        bluePrint={groupDisplayBluePrint[groupMeta.key]} 
                        data={cardData[groupMeta.key]}/> 
                }


            </div>

        </div>
    )

}


export default DescriptionContainer;